import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <title>Loader Logo</title>
    <g>
      <g id="B" transform="translate(11.000000, 5.000000)">
        <path
          d="M49.76,49.63q0,11.09,1.22,11.09c.82,0,1.92-.95,3.3-2.83a40.08,40.08,0,0,0,4.09-7.28q2-4.45,2.45-4.46c.29,0,.44.18.44.55q0,1.9-2.31,6.94Q54,64.19,50,64.19a9.22,9.22,0,0,1-2.72-.38,3.28,3.28,0,0,1-1.5-.71q-.68-.81-.68-6A192.27,192.27,0,0,1,46,37.24a53.72,53.72,0,0,0-7.22,8.84,37.73,37.73,0,0,1,1.43,9.43,10.62,10.62,0,0,1-1.53,6.16,4.56,4.56,0,0,1-3.84,2.18c-1.87,0-2.79-1.79-2.79-5.38q0-3.67,2.92-9.73a42.33,42.33,0,0,0-4-9.32,80.6,80.6,0,0,1-3.33,12.65A36.15,36.15,0,0,1,23,61.7a7,7,0,0,1-5.65,3.17C15.78,64.87,15,64.26,15,63a2.26,2.26,0,0,1,1-2.11A8.14,8.14,0,0,1,18.42,60a3.91,3.91,0,0,0,1.8-.71q2.66-2.79,5-9.53A77.92,77.92,0,0,0,28.66,36q-2.93-3.81-2.92-5.69a5.65,5.65,0,0,1,1-3.3A3.66,3.66,0,0,1,30,25.53c.46,0,.84.58,1.16,1.74a13.61,13.61,0,0,1,.48,3.64,51.4,51.4,0,0,1,6.33,12.52,72.84,72.84,0,0,1,8.71-11.57Q48.73,19,51.52,19c.64,0,1.31.48,2,1.43a4.85,4.85,0,0,1,1.06,2.93q0,4.62-4,9.39A131,131,0,0,0,49.76,49.63Zm-14.16,2A15.85,15.85,0,0,0,34,58.1c0,1.88.23,2.82.68,2.82s.79-.56,1-1.67a17.25,17.25,0,0,0,.34-3.5A23.08,23.08,0,0,0,35.6,51.67Z"
          fill="currentColor"
        />
      </g>
      <path
        stroke="currentColor"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M 50, 5
                  L 11, 27
                  L 11, 72
                  L 50, 95
                  L 89, 73
                  L 89, 28 z"
      />
    </g>
  </svg>
);

export default IconLoader;
